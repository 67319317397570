import React, { useEffect } from 'react';
import './styles.scss';
import '../../styles/_index.scss';
// Components
// import CustomLink from '../../components/custom-link/customLink';
import Footer from '../../components/footer/footer';
import Input from '../../components/input/input';
import Textarea from '../../components/textarea/textArea';
import Button from '../../components/button/button';
import Checkbox from '../../components/checkbox/checkbox';
import Navigation from '../../components/navigation/navigation';
// Assets
import Wave1 from '../../assets/waves/wave-1-white.svg';
// import Wave3White from '../../assets/waves/wave-3-white.svg';
// import Mission2 from '../../assets/photos/mission-2.png';
import { ReactComponent as Star } from '../../assets/graphics/AC-logo-asterisk.svg';

//import LisaHealth from '../../assets/logos/client/midday.png';
// import ChangeaPath from '../../assets/logos/client/capLogo.png';
// import MindFulLife from '../../assets/logos/client/logo-nav.png';
// import PCRC from '../../assets/logos/client/pbwclogo.png';
// import CollegeMapper from '../../assets/logos/client/CollegeMapper-Logo-RGB.png';
// import techagainsttrafficking from '../../assets/logos/client/tat.png';

import Atlanta from '../../assets/photos/cities/Atlanta.png';
import Memphis from '../../assets/photos/cities/Memphis.jpg';
import Seattle from '../../assets/photos/cities/Seattle.png';
import NewYork from '../../assets/photos/cities/NewYork.jpg';
import LA from '../../assets/photos/cities/LA.png';
import Dallas from '../../assets/photos/cities/Dallas.webp';
import SF from '../../assets/photos/cities/SF.png';

const encode = data => {
	return Object.keys(data)
	  .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
	  .join("&");
  };

const SetUpAConsult = () => {
	const [userObject, setUserObject] = React.useState({});
	const [submitted, setSubmitted] = React.useState(false);


	const handleChange = e => {
		var userNewObject = userObject;
		userNewObject[e.target.name] = e.target.value
		setUserObject(userNewObject);
		console.log(submitted)
	}


	const handleSubmit = e => {
		console.log(userObject)
		fetch("/", {
		  method: "POST",
		  headers: { "Content-Type": "application/x-www-form-urlencoded" },
		  body: encode({ "form-name": "contactv2", ...userObject })
		})
		.then((response) => {
			setSubmitted(true)
			setUserObject({})
			})
		  .catch(error => alert(error));
	
		e.preventDefault();
	  };

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="ac-volunteer">
			<Navigation selected='hire us'></Navigation>
			<section className="ac-consult-hero" aria-labelledby="hero-heading">
				<div className="ac-consult-hero-container">
					<h1 className="ac-consult-heading" id="hero-heading">
						Contact Us
					</h1>
					<p className="desktopWidth45Percent ac-volunteer-body">
						Let us know how we can work together in your city!
					</p>
				</div>
				<Star className="ac-consult-hero-star"></Star>
				<Star className="ac-consult-hero-star"></Star>
			</section>

			<section
				className="ac-request-consult"
				aria-labelledby="request-consult-heading">
				<div className='ac-request-consult-inner'>
					<div className="positionRelative ac-request-consult-container">
					{submitted ? (
						<div className="ac-request-consult-form">
						<h2
						className="ac-volunteer-subheading"
						id="become-partner-heading">
						Thank you for submitting!
						</h2>
						</div>
					) : (
						<form className="ac-request-consult-form"  onSubmit={handleSubmit}>
							<div>
								<h2 className="marginBottom30 ac-request-consult-subheading"
									id="request-consult-heading">Reach out to contact us</h2>
								<p className='marginBottom40 lineHeightMd'>Tell us a little about yourself and how we can partner together.</p>
							</div>
							<div className="ac-request-consult-form-container">
								<div className="ac-request-consult-form-column">
									<Input label="First name" name="firstName" required onChange={handleChange}></Input>
									<Input label="Last name" name="lastName" required onChange={handleChange}></Input>
									<Input label="Email" name="userEmail" required onChange={handleChange}></Input>
								</div>
								<div className="ac-request-consult-form-column">
									<Textarea label="Your message" name="userMessage" required onChange={handleChange}></Textarea>
									<Checkbox label="I’d like to receive additional information regarding AnnieCannons"></Checkbox>
									<Button label="Submit" variant="primary"></Button>
								</div>
							</div>
						</form>)}

						<Star className="ac-request-consult-star"></Star>
						<Star className="ac-request-consult-star"></Star>
						<Star className="ac-request-consult-star"></Star>
					</div>

					<div className="ac-consult-clients" aria-labelledby="clients-heading">
						<div
							className="ac-consult-clients-top-wave"
							style={{ backgroundImage: `url(${Wave1})`, top: '-136px' }}>
						</div>
						<div className="ac-consult-clients-container">
							<h2 className="marginBottom30 ac-consult-subheading" id="clients-heading">Our Cities</h2>
							<p className='marginBottom60 lineHeightMd'>Whether you're a local changemaker or a national supporter, there's a role for you in this movement. Together, we can create lasting economic opportunities, dismantle systemic barriers, and prove the power of survivor intelligence on a national scale.</p>
							<div className="ac-consult-clients-content">
								<div style={{display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
									<img src={Memphis} alt="Memphis" className="ac-consult-clients-logos" />
									<p style={{textAlign: 'center'}}>Memphis</p>
								</div>
								<div style={{display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
									<img src={Atlanta} alt="Atlanta" className="ac-consult-clients-logos" />
									<p style={{textAlign: 'center'}}>Atlanta</p>
								</div>
								<div style={{display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
									<img src={NewYork} alt="New York" className="ac-consult-clients-logos" />
									<p style={{textAlign: 'center'}}>New York</p>
								</div>
								<div style={{display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
									<img src={Seattle} alt="Seattle" className="ac-consult-clients-logos" />
									<p style={{textAlign: 'center'}}>Seattle</p>
								</div>
								<div style={{display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
									<img src={Dallas} alt="Dallas" className="ac-consult-clients-logos" />
									<p style={{textAlign: 'center'}}>Dallas</p>
								</div>
								<div style={{display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
									<img src={SF} alt="SF" className="ac-consult-clients-logos" />
									<p style={{textAlign: 'center'}}>San Francisco Bay Area</p>
								</div>
								<div style={{display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
									<img src={LA} alt="LA" className="ac-consult-clients-logos" />
									<p style={{textAlign: 'center'}}>Los Angeles</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer></Footer>
		</div>
	);
}

export default SetUpAConsult;
